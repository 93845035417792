<template>
  <div
    style="
      position: fixed;
      top: 100px;
      left: 0px;
      color: #fff;
      width: 100%;
      text-align: center;
    "
  >
    {{ hint }}
  </div>
</template>

<script>
import querystring from "querystring";
import { QYLogin } from "@/api/index.js";
import { setSessionStr, setSessionObj } from "@/utils/session.js";

export default {
  data() {
    return {
      hint: "正在登录 ...",
    };
  },

  mounted() {
    let objUser = querystring.parse(location.hash.split("?")[1]);
    this.$store.commit("setOpenID", objUser.uid);
    this.$store.commit("setFaceUrl", "");
    this.$store.commit("setCompanyId", objUser.cid);
    if (objUser.uid != undefined && objUser.uid != "") {
      this.UserLogin(objUser.cid, objUser.uid);
    }
  },

  methods: {
    UserLogin(_cid, _uid) {
      QYLogin(
        {
          wwUserId: _uid,
          wwCompanyId: _cid,
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              this.$store.commit("setToken", ret.data.token);
              this.$store.commit("setUser", ret.data.user);
              this.$store.commit("setCompanys", ret.data.companys);
              this.$store.commit("setDepts",this.$Tools.sortBykey(ret.data.depts, "id"));
              this.$store.commit("setGroup", ret.data.group);
              this.$store.commit("setInspector", ret.data.inspector);
              this.$store.commit("setPermission", ret.data.permission);
              this.$store.commit("setPlace", ret.data.place);
              this.$store.commit("setRoutes",this.$Tools.sortBykey(ret.data.routes, "id"));
              this.$store.commit("setDevices", ret.data.devices==undefined?[]:ret.data.devices);

              //初始化查询条件
              let date1 = this.$Tools.FormatDate2(new Date()) + " 00:00:01";
              let date2 = this.$Tools.FormatDate2(new Date()) + " 23:59:59";
              setSessionStr("Query_Date", this.$t("lang.today"));
              setSessionStr("Query_Date_1", date1);
              setSessionStr("Query_Date_2", date2);

              let com_name =
                ret.data.companys.length > 0 ? ret.data.companys[0].name : "";
              let com_id =
                ret.data.companys.length > 0 ? ret.data.companys[0].id : 0;
              setSessionStr("Query_Company", com_name);
              setSessionStr("Query_Company_id", com_id);

              let dept_name = "";
              let dept_id = 0;
              if (ret.data.depts.length > 0) {
                let lstData = ret.data.depts.filter((d) => {
                  return d.companyId == com_id;
                });
                dept_name = lstData.length > 0 ? lstData[0].name : "";
                dept_id = lstData.length > 0 ? lstData[0].id : 0;
              }
              setSessionStr("Query_Dept", dept_name);
              setSessionStr("Query_Dept_id", dept_id);
              setSessionStr("Query_RepairType", "-1");
              setSessionStr("Query_Record_miss", "0");
              setSessionStr("Query_Record_local", "1");

              //参数
              let par = {
                mCompany: com_id,
                mDept: dept_id,
                mDate1: date1,
                mDate2: date2,
                mLine: "",
                mAddr: "",
                mGroup: "",
                mUser: "",
                mDevice:"",
                mRecord: "-1",
                mRecord_miss: "0",
                mRecord_local: "1",
                mState: "",
                mOrder: "desc",
                mTotalType: "4",
                mRepairType: "-1",
                mKeyword: "",
                pagesize: 20,
              };
              setSessionObj("Query_Params", par);

              this.$store.commit("setIsXunjianApp", false);
              this.$router.replace({ path: "/query/index" });
              
            } else this.hint = this.$t("lang.login_fail") + "：" + ret.message;
            //if (ret.code != 9) this.$Toast(ret.message);
          } else this.hint = this.$t("lang.login_fail");
        }
      );
    },
  },

  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#336666");
  },
  //#0184b2
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
};
</script>
